import React from "react"
import { FaArrowRight } from "react-icons/fa"

import SEO from "../components/seo"

import "../scss/contactus.scss"
import "../scss/youcloud.scss"
import "../scss/contactform.scss"

import EmailIcon from "../assets/img/youCloud/contactUs/icon1.png"
import CallIcon from "../assets/img/youCloud/contactUs/icon2.png"
import SupportIcon from "../assets/img/youCloud/contactUs/icon3.png"
import mapImage from "../assets/img/youCloud/contactUs/Map.svg"
import appendScript from '../utils/appendScript';

const ContactUsPage = () => {
  appendScript("https://ma.youpal.se/form/generate.js?id=29", "contact-us-mautic-form");

  return (
    <div>
      <SEO title="Contact Us" keywords={[`youCloud`, `youpal group`]} />
      <section className="container contactUsPageSection">
        <section className="container contactUsBackground">
          <div className="row">
            <div className="col-sm">
              <h2 className="internalNarrowCol pt-5 gradiantHeader">
                Contact Us
              </h2>
              <p className="internalNarrowCol">
                A team of extremely trustworthy people is working every day,
                evaluating on your feedback.
              </p>
            </div>
            <div className="col-sm map">
              <img src={mapImage} />
            </div>
          </div>
        </section>
      </section>

      <section className="container contactUsPageSection">
        <h2>Reach Out</h2>
        <p className="contactUsSubtitle">
          Our team of amazing and awesome individuals is always ready to give
          you answers to your questions.
        </p>
        <div className="formDiv">
          {/* Mautic form */}
          <div id='contact-us-mautic-form'/>
        </div>
      </section>

      <section className="container contactsSection">
        <div className="row">
          <div className="col iconP">
            <img src={EmailIcon} />
            <h3>Email us</h3>
            <p>
              If you have any additional or urgent queries reach us on the
              following email:
            </p>
            <p className="plink">
              <a href="mailto:hello@youpal.cloud">hello@youpal.cloud</a>
            </p>
          </div>
          <div className="col iconP">
            <img src={CallIcon} />
            <h3>Call us</h3>
            <p>
              If you really want to hear our voice, please reach out to the
              following number:
            </p>
            <p className="plink">
              <a href="tel:+4681-241-1707">+46 8-124 117 07</a>
            </p>
          </div>
          <div className="col iconP">
            <img src={SupportIcon} />
            <h3>Support</h3>
            <p>
              You can also get lucky via contacting one of our amazing team
              members via live chat
            </p>
            <p className="plink">
              <a href="mailto:hello@youpal.cloud">
                <span style={{ marginRight: "20px" }}>Go chatting</span>
                <FaArrowRight style={{ fill: "#473BF0" }} />
              </a>
            </p>
          </div>
        </div>
      </section>

      {/* <section className="container contactBackground">
        <div className="row contactBox">
          <div className="col">
            <h1 className="gradiantHeader">
              The sky’s the limit, but so is the cloud
            </h1>
            <div className="sendLinkContainer">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="your@email.com"
                  aria-label="Your Email"
                  aria-describedby="button-addon2"
                />
                <div className="input-group-append">
                  <button
                    className="px-4 py-2 btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                  >
                    Join Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  )
}

export default ContactUsPage
