import { useEffect } from 'react';

const appendScript = (resourceUrl, selector) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = true;
    document.getElementById(selector).prepend(script);
    return () => {
      document.getElementById(selector).removeChild(script);
    }
  }, [resourceUrl]);
};

export default appendScript;